// src/chainId.ts
var ChainId = /* @__PURE__ */ ((ChainId2) => {
  ChainId2[ChainId2["SWAN_SATURN"] = 2024] = "SWAN_SATURN";
  return ChainId2;
})(ChainId || {});

// src/chainNames.ts
var chainNames = {
  [2024 /* SWAN_SATURN */]: "modeTestnet"
};
var chainNameToChainId = Object.entries(chainNames).reduce(
  (acc, [chainId, chainName]) => {
    return {
      [chainName]: chainId,
      ...acc
    };
  },
  {}
);
var defiLlamaChainNames = {
  [2024 /* SWAN_SATURN */]: "swan"
};

// src/utils.ts
function getChainName(chainId) {
  return chainNames[chainId];
}
function getLlamaChainName(chainId) {
  return defiLlamaChainNames[chainId];
}
function getChainIdByChainName(chainName) {
  if (!chainName)
    return void 0;
  return chainNameToChainId[chainName] ?? void 0;
}

// src/subgraphs.ts
var V3_SUBGRAPHS = {
  [2024 /* SWAN_SATURN */]: ""
};
var V2_SUBGRAPHS = {
  [2024 /* SWAN_SATURN */]: "http://127.0.0.1:8000/subgraphs/name/example"
};

export { ChainId, V2_SUBGRAPHS, V3_SUBGRAPHS, chainNameToChainId, chainNames, defiLlamaChainNames, getChainIdByChainName, getChainName, getLlamaChainName };
