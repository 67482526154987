import { ERC20Token, WNATIVE, Native } from '@pancakeswap/sdk';
import { ChainId } from '@pancakeswap/chains';

// src/common.ts
var MIE_TESTNET = new ERC20Token(
  ChainId.SWAN_SATURN,
  "0x4D025605eA6a069857002e4548e2ae22c9E6c0Df",
  18,
  "ANDX",
  "Mie Token",
  "https://mieswap.xyz/"
);
var USDC_BSC = new ERC20Token(
  ChainId.SWAN_SATURN,
  "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  18,
  "USDC",
  "Binance-Peg USD Coin",
  "https://www.centre.io/usdc"
);
var USDC_TESTNET = new ERC20Token(
  ChainId.SWAN_SATURN,
  "0x3FC76FAa4cB34853a73382dF41353f6B568A3116",
  18,
  "USDC",
  "Binance-Peg USD Coin",
  "https://www.centre.io/usdc"
);
var USDC_ETH = new ERC20Token(
  ChainId.SWAN_SATURN,
  "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  6,
  "USDC",
  "USD Coin"
);
var USDC_GOERLI = new ERC20Token(
  ChainId.SWAN_SATURN,
  "0x4b3B37c081e4cA016574c3855799bD24732d0684",
  18,
  "tUSDC",
  "test USD Coin"
);
var USDT_BSC = new ERC20Token(
  ChainId.SWAN_SATURN,
  "0x55d398326f99059fF775485246999027B3197955",
  18,
  "USDT",
  "Tether USD",
  "https://tether.to/"
);
var USDT_ETH = new ERC20Token(
  ChainId.SWAN_SATURN,
  "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  6,
  "USDT",
  "Tether USD",
  "https://tether.to/"
);
var BUSD_BSC = new ERC20Token(
  ChainId.SWAN_SATURN,
  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  18,
  "BUSD",
  "Binance USD",
  "https://www.paxos.com/busd/"
);
var BUSD_TESTNET = new ERC20Token(
  ChainId.SWAN_SATURN,
  "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814",
  18,
  "BUSD",
  "Binance USD",
  "https://www.paxos.com/busd/"
);
var BUSD_ETH = new ERC20Token(
  ChainId.SWAN_SATURN,
  "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
  18,
  "BUSD",
  "Binance USD",
  "https://www.paxos.com/busd/"
);
var BUSD_GOERLI = new ERC20Token(
  ChainId.SWAN_SATURN,
  "0xbf09acbE62F9883D7FCb05dA735555Bb4846E710",
  18,
  "BUSD",
  "Binance USD",
  "https://www.paxos.com/busd/"
);
var BUSD = {
  [ChainId.SWAN_SATURN]: BUSD_ETH
};
var MIE = {
  [ChainId.SWAN_SATURN]: new ERC20Token(
    ChainId.SWAN_SATURN,
    "0x91B25A65b295F0405552A4bbB77879ab5e38166c",
    18,
    "MIE",
    "Mie Token",
    "https://mieswap.xyz/"
  )
};
var USDC = {
  // [ChainId.SWAN_SATURN]: USDC_ETH,
};
var USDT = {
  [ChainId.SWAN_SATURN]: USDT_ETH
};
var STABLE_COIN = {};

// ../utils/enumValues.ts
function* enumValues(enumObj) {
  let isStringEnum = true;
  for (const property in enumObj) {
    if (typeof enumObj[property] === "number") {
      isStringEnum = false;
      break;
    }
  }
  for (const property in enumObj) {
    if (isStringEnum || typeof enumObj[property] === "number") {
      yield enumObj[property];
    }
  }
}

// src/helpers.ts
var createEmptyList = () => {
  const list = {};
  for (const chainId of enumValues(ChainId)) {
    list[chainId] = {};
  }
  return list;
};
var EMPTY_LIST = createEmptyList();
function serializeTokens(unserializedTokens) {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: unserializedTokens[key].serialize };
  }, {});
  return serializedTokens;
}
function unwrappedToken(token) {
  if (token && token.equals(WNATIVE[token.chainId]))
    return Native.onChain(token.chainId);
  return token;
}

export { BUSD, BUSD_BSC, BUSD_ETH, BUSD_GOERLI, BUSD_TESTNET, EMPTY_LIST, MIE, MIE_TESTNET, STABLE_COIN, USDC, USDC_BSC, USDC_ETH, USDC_GOERLI, USDC_TESTNET, USDT, USDT_BSC, USDT_ETH, serializeTokens, unwrappedToken };
